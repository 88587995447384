import React from "react";
import { Link } from "gatsby";

import StaticLayout from "../components/StaticLayout";

const Page = () => (
  <StaticLayout isSingle>
    <main id="main" className="site-main" role="main">
      <div className="svg-container single-post-svg svg-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 1890 150"
        >
          <g transform="translate(0,-902.36218)" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 1925,0 0,150 -1925,0" />
        </svg>
      </div>
      <article
        id="post-5"
        className="post-5 post type-post status-publish format-standard hentry category-geen-categorie"
      >
        <header className="entry-header">
          <h1 className="entry-title">Salsa &amp; Bachata</h1>
          <div className="entry-meta" />
          {/* .entry-meta */}
        </header>
        {/* .entry-header */}
        <div className="entry-content">
          <p>
            Droom jij bij het horen van zuiderse muziek wel eens weg naar
            Midden-Amerika en wil je graag even goed kunnen dansen als de echte
            latino’s en latina’s? Dan zijn salsa en bachata zeker jouw ding.
          </p>
          <p>
            <strong>Salsa</strong> is een populaire dans die zijn oorsprong
            voornamelijk in Cuba vindt.&nbsp;Het is moeilijk om een definitie te
            geven van salsa, want in verschillende landen, en zelfs in
            verschillende steden wordt hij anders gedanst. Eén ding hebben alle
            salsadansers wél gemeen: hun heupen staan tijdens het dansen niet
            stil!
          </p>
          <p>
            <strong>Bachata</strong> is minder gekend bij het grote publiek dan
            salsa maar is zeker even leuk. Deze dansstijl is afkomstig uit de
            Dominicaanse Republiek en is in verschillende vormen terug te
            vinden. Er komen standaard heel wat heupzwierbewegingen en
            traditionele passen bij te kijken. Obsession van Avontura is
            bijvoorbeeld een bekend Bachata liedje.
          </p>
          <p>
            Zin om te dansen in zuiderse sferen? Waag je dan aan onze snelcursus
            Salsa &amp; Bachata.
          </p>
        </div>
        {/* .entry-content */}
        <footer className="entry-footer" />
        {/* .entry-footer */}
      </article>
      {/* #post-## */}
      <div className="svg-container single-post-svg single-svg-bottom svg-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 1920 150"
        >
          <g transform="translate(0,-902.36218)" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="M 0,150 0,0 1925,0" />
        </svg>
      </div>
      <nav className="navigation post-navigation" role="navigation">
        <h2 className="screen-reader-text">Berichtnavigatie</h2>
        <div className="nav-links">
          <div className="nav-previous">
            <Link to="/">Terug naar de startpagina</Link>
          </div>
        </div>
      </nav>
    </main>
  </StaticLayout>
);

export default Page;
